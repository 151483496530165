/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { FC, useState, memo, useEffect, useRef } from 'react';
import GTM from '@utils/gtm';
import { useTranslation as t } from '@utils/hooks';
import debounce from 'lodash.debounce';
import { isEqual } from 'lodash';
import Router from 'next/router';
import type { Fps, SpecsCardProps } from '@framework/api/types';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import { FpsCard, SpecsCard } from './Cards';

import * as styles from './styles';

type Props = {
  fps?: {
    chipsetType?: string;
    fps: Fps;
  };
  keySpecs?: SpecsCardProps[];
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  overrideHeading?: string;
  isSubheading?: boolean;
  fullBleed?: boolean;
  noFpsBackground?: boolean;
};

const ResizePlugin = (slider): void => {
  const observer = new ResizeObserver(() => {
    slider.update();
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

const KeySpecs: FC<Props> = ({
  fps,
  keySpecs,
  isSm,
  isMd,
  isLg,
  isXl,
  overrideHeading,
  isSubheading = false,
  fullBleed = false,
  noFpsBackground = false,
}) => {
  const KEY_SPECS_LABEL = t('key_specs_label');
  const MOVE_TO_PREV = t('move_to_previous_label');
  const MOVE_TO_NEXT = t('move_to_next_label');
  const ACTIVE_SLIDE = t('active_slide_label');
  const MOVE_TO_INACTIVE = t('move_to_inactive_slide');

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const sizeOfCard = isSm ? '100%' : 445;
  const perView = isSm ? 1 : 'auto';
  const is2XL = !isSm && !isMd && !isLg && !isXl;

  const getEndOfTrackValue = (): number => {
    if (is2XL) {
      return 0.16;
    }
    if (isXl) {
      return 0.2;
    }

    if (isLg) {
      return 0.22;
    }
    return 0.2;
  };

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      slides: { perView, spacing: 15 },
      mode: 'free-snap',
      loop: false,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel);
        GTM.dataLayer({
          dataLayer: {
            event: 'keySpecsInteraction',
          },
        });
      },
      created() {
        setLoaded(true);
      },
    },
    [ResizePlugin]
  );

  const resetSlide = (): void => {
    if (instanceRef?.current) {
      instanceRef.current?.update({
        slides: { perView, spacing: 15 },
        mode: 'free-snap',
        loop: false,
        slideChanged(s) {
          setCurrentSlide(s.track.details.rel);
          GTM.dataLayer({
            dataLayer: {
              event: 'keySpecsInteraction',
            },
          });
        },
        created() {
          setLoaded(true);
        },
      });
      instanceRef.current?.moveToIdx(0);
    }
  };

  const debouncedUnfocus = debounce(resetSlide, 250);
  useEffect(() => {
    Router.events.on('routeChangeComplete', debouncedUnfocus);

    return () => {
      Router.events.off('routeChangeComplete', debouncedUnfocus);
    };
  }, [instanceRef, debouncedUnfocus]);

  const prevKeySpecsRef = useRef<SpecsCardProps[] | undefined>();

  useEffect(() => {
    if (!isEqual(prevKeySpecsRef.current, keySpecs)) {
      resetSlide();
      prevKeySpecsRef.current = keySpecs;
    }
  }, [keySpecs, resetSlide]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickToNext = (e: any): void => {
    e.stopPropagation();
    instanceRef.current?.next();
    GTM.dataLayer({
      dataLayer: {
        event: 'keySpecsArrow',
        interActionType: 'next',
      },
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickToPrev = (e: any): void => {
    e.stopPropagation();
    instanceRef.current?.prev();
    GTM.dataLayer({
      dataLayer: {
        event: 'keySpecsArrow',
        interActionType: 'previous',
      },
    });
  };

  // TO DO: Translation the items below
  return keySpecs && Array.isArray(keySpecs) && keySpecs.length > 0 ? (
    <SectionWrapper noSpacing={fullBleed}>
      <ContentWrapper>
        <div className={styles.headerWrapper}>
          {overrideHeading !== '' && (
            <h2 className={styles.header(isSubheading)}>
              {overrideHeading || KEY_SPECS_LABEL}
            </h2>
          )}

          {loaded && instanceRef.current && (
            <div className={styles.arrowContainer(isSm === true)}>
              <button
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any): void => handleClickToPrev(e)}
                disabled={currentSlide === 0}
                type="button"
              >
                <IconChevronLeft className={styles.arrow(currentSlide === 0)} />
                <span className={styles.srOnly}>{MOVE_TO_PREV}</span>
              </button>

              <button
                type="button"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any): void => handleClickToNext(e)}
                disabled={
                  instanceRef?.current &&
                  Boolean(
                    instanceRef?.current?.track.details.length > 0
                      ? instanceRef.current.track.details.max -
                          instanceRef.current.track.details.position <
                          getEndOfTrackValue()
                      : true
                  )
                }
              >
                <IconChevronRight
                  className={styles.arrow(
                    instanceRef?.current &&
                      Boolean(
                        instanceRef.current?.track.details.length > 0
                          ? instanceRef.current.track.details.max -
                              instanceRef.current.track.details.position <
                              getEndOfTrackValue()
                          : true
                      )
                  )}
                />
                <span className={styles.srOnly}>{MOVE_TO_NEXT}</span>
              </button>
            </div>
          )}
        </div>

        <div
          ref={sliderRef}
          className="keen-slider grabbyHand"
          style={{ maxWidth: 1376 }}
        >
          {fps && Array.isArray(fps?.fps) && fps?.fps.length > 0 ? (
            <div
              className="keen-slider__slide number-slide1"
              style={{ maxWidth: sizeOfCard, minWidth: sizeOfCard }}
            >
              <FpsCard
                fps={fps}
                chipsetType={fps?.chipsetType}
                noBackground={noFpsBackground}
                noContentWrapper={noFpsBackground}
              />
            </div>
          ) : null}

          {keySpecs &&
            Array.isArray(keySpecs) &&
            keySpecs.length > 0 &&
            keySpecs?.map((spec, i) => (
              <div
                key={spec.id}
                className={`keen-slider__slide number-slide${
                  fps && Array.isArray(fps?.fps) && fps?.fps.length > 0
                    ? i + 2
                    : i
                }`}
                style={{ maxWidth: sizeOfCard, minWidth: sizeOfCard }}
              >
                <SpecsCard
                  heading={spec.heading}
                  subheading={spec.subheading}
                  rating={spec.rating}
                  details={spec.details}
                  modalLabel={spec.modalLabel}
                  modalContent={spec.modalContent}
                  keySpecsCardModalMarkdown={spec.keySpecsCardModalMarkdown}
                  noBackground={noFpsBackground}
                />
              </div>
            ))}
        </div>
        <div className={styles.pipContainer(isSm === false)}>
          {loaded &&
            instanceRef.current &&
            keySpecs &&
            Array.isArray(keySpecs) &&
            keySpecs.length > 0 &&
            [
              ...Array(instanceRef?.current.track.details.slides.length).keys(),
            ].map(idx => (
              <button
                type="button"
                key={idx}
                onClick={() => {
                  instanceRef?.current?.moveToIdx(idx);
                }}
                className={styles.pip(currentSlide === idx)}
              >
                <span className={styles.srOnly}>
                  {currentSlide === idx
                    ? `${ACTIVE_SLIDE} ${currentSlide}`
                    : `${MOVE_TO_INACTIVE} ${idx}`}
                </span>
              </button>
            ))}
        </div>
      </ContentWrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(KeySpecs);
